import Cookies from 'js-cookie'
import $ from 'jquery'

const html = $('html')
const is_preview = html.attr('data-is-preview') === "true"
const addthis_enabled = html.attr('data-addthis-enabled') === "true"

if (!is_preview && addthis_enabled && Cookies.get('cookieconsent_status') === 'allow') {
  window.addthis_config = {
    data_use_cookies_ondomain: !1,
    data_use_cookies: !1,
    lang: $('html').attr('lang'),
  }

  // DOM: Create the script element
  var jsElm = document.createElement("script");
  // Set the type attribute
  jsElm.type = "application/javascript";
  // Make the script element load file
  jsElm.src = "//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5f69b392b66f2a24";
  // Finally insert the element to the body element in order to load the script
  document.head.appendChild(jsElm)
}
